<template>
  <div class="grid">
    <Dialog v-model:visible="showProgressSpinner" modal :closable="false" :style="{ width: '50vw' }">
			<ProgressSpinner/>
		</Dialog>
    <Panel :header="$t('shipment.document')" class="col-12 md:col-12">
      <div class="grid col-12 md:col-12">
        <div class="md:col-6">
          <div class="grid" style="margin-top: 0px">
            <label for="shipmentCode" class="md:col-3" style="font-weight: bold;">{{$t('shipment.shipmentCode')}}</label>
            <div id="shipmentCode" class="md:col-3" style="font-weight: bold">{{ shipment.shipmentCode }}</div>
          </div>
          <div class="grid" style="margin-top: 0px">
            <label for="referenceCode" class="md:col-3" style="font-weight: bold;">{{$t('shipment.referenceCode')}}</label>
            <div id="referenceCode" class="md:col-3" style="font-weight: bold">{{ shipment.referenceCode }}</div>
          </div>
        </div>
        <div class="md:col-6">
          <div class="grid" style="margin-top: 0px">
            <label for="weightPartnerLbs" class="md:col-3" style="font-weight: bold;">{{$t('shipment.weightPartnerLbs')}}</label>
            <div id="weightPartnerLbs" class="md:col-3" style="font-weight: bold">{{ shipment.weightPartnerLbs || 0 }} {{ $t('unit.lbs') }} / {{shipment.weightPartnerKg || 0}} {{$t('unit.kg')}}</div>
          </div>
          <div class="grid" style="margin-top: 0px">
            <label for="weightActualLbs" class="md:col-3" style="font-weight: bold;">{{$t('shipment.weightActual')}}</label>
            <div id="weightActualLbs" class="md:col-3" style="font-weight: bold">{{ shipment.weightActualLbs || 0 }} {{$t('unit.lbs')}} / {{shipment.weightActualKg || 0}} {{$t('unit.kg')}}</div>
          </div>
        </div>
      </div>
    </Panel>
    <div class="grid col-12 md:col-12">
      <!--
      <div class="card p-fluid">
        <div class="field grid">
					<label for="packageCode" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('package.packageCode')}}</label>
					<div class="col-12 lg:col-4 md:col-4">
						<InputText id="packageCode" type="text" v-model="packageCode" />
					</div>
            <div class="col-1 lg:col-1 md:col-1">
              <Button :label="$t('button.save')" @click="onSave($event)"></Button>
            </div>
            <div class="col-1 lg:col-1 md:col-1">
              <Button :label="$t('button.reset')" @click="onReset($event)"></Button>
            </div>
            <div class="col-1 lg:col-1 md:col-1">
              <Button :label="$t('button.back')" @click="onBack()"></Button>
            </div>
				</div>
      </div>
      -->

      <DataTable :value="entities" showGridlines class="col-12 md:col-12">
        <Column field="stt" header="STT" headerStyle="width: 3rem">
          <template #body="slotProps">
            {{ slotProps.index + 1 }}
          </template>
        </Column>
        <Column field="documentType" :header="$t('shipment.documentType')" headerStyle="width: 18rem">
          <template #body="slotProps">
            {{ slotProps.data.documentLabel }}
          </template>
        </Column>
        <Column field="uploadFiles" :header="$t('shipment.uploadFiles')"  bodyStyle="text-align:center" headerStyle="width: 6rem">
           <template #body="{ data }">
              <FileUpload mode="basic" :customUpload="true" :auto="true"
							:maxFileSize="10240000" @upload="(event) => onUpload(event, data)" @uploader="(event) => fileUploader(event, data)" />
           </template>
        </Column>
        <Column field="downloadFiles" :header="$t('shipment.downloadFiles')">
          <template #body="slotProps">
            <div v-for="file in slotProps.data.files" :key="file.id">
              <div class="flex align-items-center">
                <i class="pi pi-times" style="color: red; cursor: pointer;" @click="onDeleteFile(file)"></i>
                <Button link @click="onDownload(file)">
                  {{ file.documentName }}
                </Button>
              </div>         
            </div>
          </template>
        </Column>
        <!-- <Column field="note" :header="$t('shipment.note')">
          <template #body="slotProps">
              <Textarea id="note" v-model="slotProps.data.note" required="false" rows="1" cols="50" />
          </template>
        </Column> -->
      </DataTable>
    </div>
  </div>
</template>
<script>
// import CrudTable from '@/pages/common/CrudTable';
import ShipmentService from '@/service/ShipmentService';
import AuthService from '@/service/AuthService';
// import adjustment from '@/utils/adjustment';
import {serializeOrders, serializeFilters } from '@/utils/params';
import { Consts, DocumentTypes } from '@/utils/consts';
//import filter from '@/mixins/filter'

export default {
  components: {},
  //mixins: [filter],
  data() {
    return {
      shipment: {},
      entities: [],
      packageCode: null,
      currentUser: null,
      showProgressSpinner: false,
      filterOptions: {
        page: 0,
				size: Consts.DEFAULT_PAGE_ROWS
      },
      metaTable : {
        entityName: "shipment",
        expander: false,
        searchable: true,
        rowEditor: true,
        editMode: "cell",
        sortMode: "multiple",
        multiSortMeta: [],
        actionsHeaderStyle: "min-width:12rem; text-align: center;",
        paginator:{
          enable: true,
          rows: Consts.DEFAULT_PAGE_ROWS,
          rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
          currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} shipments",
        },
        filter: {
          filterDisplay: "menu",
          globalFilterFields: ['shipmentCode','awbCode']
        },
        columns: [
          {
            name: "STT",
            headerStyle: "width:8%; min-width:8rem;"
          },
          {
            name: "documentType",
            label: "shipment.documentType",
            headerStyle: "width:8%; min-width:20rem;",
          },
          {
            name: "uploadFiles",
            label:"shipment.uploadFiles",
            headerStyle: "width:5%; min-width:12rem;",
          },
          {
            name: "downloadFiles",
            label:"shipment.downloadFiles",
            headerStyle: "width:10%; min-width:12rem;",
          },
          {
            name: "note",
            headerStyle: "width:20%; min-width:18rem;",
            filter: true,
          }
        ],
        tableActions: [
          {
            name: "filterclear",
            icon: "pi pi-filter-slash",
            style: "p-button-outlined mr-2"
          },
          {
            name: "create",
            icon: "pi pi-pencil",
            style: "p-button mr-2"
          },
        ],
        itemActions: [
          {
            name: "upload",
            icon: "pi pi-upload",
            style: "p-button-rounded mr-2",
            condition: "status != 'stored'"
          },
          {
            name: "download",
            icon: "pi pi-download",
            style: "p-button-rounded mr-2"
          }
        ]
      }
    }
  },
  async mounted() {
    this.service = ShipmentService;
    this.$watch(
      () => this.$route.params,
      (params) => {
        this._init(params.id);
        //console.log('route path has changed from ' +from+' to '+to )
      }
    );
    this.currentUser = AuthService.currentUser;
    this.filterOptions.sorts = serializeOrders({ multiSortMeta: this.metaTable.multiSortMeta });
    this._init(this.$route.params.id);
    //this.initColumnFilters(this.$refs.dt, this.filters);
  },
  methods: {
     async _init(shipmentId) {
      if (shipmentId) { 
        this.shipment = await this.service.get(shipmentId, {
          includes: "packages, documents"
        });
        this.entities = this.createDocuments();
      }
      // this.service.getDocuments(shipmentId, this.filterOptions).then(data => {
      //   if(Array.isArray(data.content)) {
      //     this.entities = data.content;
      //     this.metaTable.paginator.totalRecords = data.totalElements;
      //   } else {
      //     this.entities = [];
      //     this.metaTable.paginator.totalRecords = 0;
      //   }
			// });
    },
    createDocuments() {
      const entities = DocumentTypes.map(document => {
        return {
          shipmentId: this.shipment.id,
          shipmentCode: this.shipment.shipmentCode,
          documentType: document.value,
          documentLabel: document.label,
          files: []
        }
      });
      if (this.shipment.documentDtos) {
        for (const document of this.shipment.documentDtos) {
          const entity = entities.find(e => e.documentType === document.documentType);
          if (entity) {
            entity.files.push(document);
          }
        }
      }
      return entities;
    },
    onPaging(pageEvent) {
      this.filterOptions.page = pageEvent.page;
      this.filterOptions.size =pageEvent.rows;
			this._loadDocuments();
		},
    onFilter(filterEvent) {
      this.filterOptions.filter = serializeFilters(filterEvent);
      this._loadDocuments();
    },
    onSort(sortEvent) {
      this.filterOptions.sorts = serializeOrders(sortEvent);
      this._loadDocuments();
    },
   
    onTableAction(sAction) {
      if(sAction == 'create') {
        this.$router.push({ name: 'agent_shipment_create', params: {} });
      }
    },
    onItemAction(sAction, entity) {
      if(sAction == 'edit') {
        this.$router.push({ name: 'agent_shipment_edit', params: {id: entity.id} });
      } else if (sAction == 'preview') {
        //this.$router.push({ name: 'print_preview', params: {resource: "shipment", id: entity.id} });
        const routeData = this.$router.resolve({ name: 'print_preview', params: {resource: "shipment", id: entity.id} });
        window.open(routeData.href);         
      } else if (sAction == 'confirm') {
        this.onSendShipment(entity);
      } else if (sAction == 'delete') {
        //this.onDeleteShipment(entity);
      }
    },
    onSendShipment(entity) {
      let error = "";
      if (!entity.departure) {
        error = this.$t('shipment.missing_departure_date');
      } else if (!entity.awbCode) {
        error = this.$t('shipment.missing_awb_code');
      } 
      if (error) {
        this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000});
      } else {
        this.$confirm.require({
            message: this.$t("shipment.confirm_start"),
            header: this.$t("common.confirmation"),
            icon: "pi pi-exclamation-triangle",
            accept: () => {
              this._sendShipment(entity);
            },
            reject: () => {
              //callback to execute when user rejects the action
            },
          });        
      }      
    },
    _sendShipment(entity) {
      this.service.startShipment(entity.id).then(()=>{
          this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('shipment.confirmed'), life: 3000});
        }).catch((e) => {
          console.log(e);
        });
    },
    // onDeleteShipment(entity) {
    //   this.$confirm.require({
    //     message: this.$t("shipment.confirm_delete"),
    //     header: this.$t("common.confirmation"),
    //     icon: "pi pi-exclamation-triangle",
    //     accept: () => {
    //       this._deleteShipment(entity);
    //     },
    //     reject: () => {
    //       //callback to execute when user rejects the action
    //     },
    //   });        
    // },
    // _deleteShipment(entity) {
    //   this.service.delete(entity.id).then(()=>{
    //       this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('shipment.deleted_successfully'), life: 3000});
    //     });
    // },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      switch (field) {
          case 'weightLbs':
          case 'weightKg':
              if (this.isPositiveInteger(newValue)) {
                  data[field] = newValue;
                  this.service.updateField(data.id, field, newValue);
              } else {
                  event.preventDefault();
              }
          break;

          default:
              if (newValue != null && newValue.trim().length > 0) {
                  data[field] = newValue;
                  this.service.updateField(data.id, field, newValue);
              }
          break;
      }
    },
    isPositiveInteger(val) {
        let str = String(val);
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    },
    openLink(data) {
      this.$router.push({ name: 'agent_shipment_detail', params: {id: data.id} })
    },
    async fileUploader(event, entity) {
      const uploadFile = event.files ? event.files[0] : null;
      if (uploadFile) {
        const blob = new Blob([uploadFile], { type: uploadFile.type });
        //const buffer = await uploadFile.arrayBuffer();
        try {
          const res = await ShipmentService.uploadDocument(blob, {
            shipmentId: entity.shipmentId,
            documentType: entity.documentType,
            documentName: uploadFile.name
          });
          if (res) {
            this.$toast.add({ severity: 'success', summary: 'Successful', detail: this.$t('shipment.uploadDocumentSuccess'), life: 3000 });
            this._init(entity.shipmentId);
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    onUpload(event, entity) {
      console.log(event, entity);
			const rawData = this._cleanData();
			if (Array.isArray(rawData) && rawData.length > 0) {
				this.showProgressSpinner = true;
				ShipmentService.upload(this.infos, rawData).then(res => {
					console.log(res);
					this.showProgressSpinner = false;
					this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('address.imported'), life: 3000});
				}).catch((e) => {
					console.log(e);
					this.showProgressSpinner = false;
				});
			}
    },
    async onDeleteFile(file) {
      const res = await ShipmentService.deleteDocument(file.id);
      if (res) {
        this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('shipment.deleted_successfully'), life: 3000});
        this._init(this.shipment.id);
      }
    },
    async onDownload(entity) {
      const res = await ShipmentService.downloadDocument(entity);
      console.log(res);
    }
  }
}
</script>
